import React from 'react';
import { styled, theme } from '../../Theme';
import ErrorIcon from '../../Shared/Icons/ErrorIcon';

type PropTypes = {
  children: JSX.Element | string;
};

function Alert({ children }: PropTypes) {
  return (
    <ErrorAlert>
      <IconWrapper>
        <StyledErrorIcon />
      </IconWrapper>
      <AlertText>{children}</AlertText>
    </ErrorAlert>
  );
}

export default Alert;

const ErrorAlert = styled.div({
  display: 'flex',
  padding: {
    xy: theme.spacing(4),
  },
  width: '100%',
  marginBottom: theme.spacing(6),
  backgroundColor: theme.errorPrimary,
});

const AlertText = styled.p({
  font: { size: theme.alpha, weight: theme.fontWeight.bold },
  lineHeight: theme.lineHeight.relaxed,
  color: theme.errorText,
});

const IconWrapper = styled.div({
  display: 'block',
  width: theme.spacing(4),
  height: theme.spacing(4),
  marginRight: theme.spacing(2),
});

const StyledErrorIcon = styled(ErrorIcon, {
  width: theme.spacing(4),
  height: theme.spacing(4),
  fill: theme.errorText,
});
