import React from 'react';
import { styled, theme } from '../../Theme';
import { media } from '@glitz/core';
import { RegistrationFormSettersType } from '../Models/RegistrationFormSetters';
import { SmallButton } from '../../Shared/Button/Button';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import RegistrationPageModel from '../Models/RegistrationPageModel.interface';
import { useAppSettingsData } from '../../Shared/AppSettingsProvider/AppSettingsProvider';
import CompanyInfoProps from './../CompanyInfoProps.interface';

type PropTypes = {
  updater: RegistrationFormSettersType;
  companyInfo: CompanyInfoProps;
};

function SecondStep({ updater, companyInfo }: PropTypes) {
  const { step2Info, step2Heading } = useCurrentPage<RegistrationPageModel>();
  const {
    translations: {
      'registrationpage/steps/shippingTextLabel': shippingTextLabel,
      'common/proceed': proceedLabel,
      'common/back': backLabel,
    },
  } = useAppSettingsData();

  return (
    <>
      <Heading>{step2Heading}</Heading>
      <DetailText>{step2Info}</DetailText>
      <CompanyInfoContainer>
        {companyInfo.companyName && companyInfo.customerNumber && (
          <Heading>{`${companyInfo.companyName} - ${companyInfo.customerNumber}`}</Heading>
        )}
        <AddressHeading>{shippingTextLabel}</AddressHeading>
        <styled.Span>
          {companyInfo.shippingAddress.streetAddress && (
            <DetailText>{companyInfo.shippingAddress.streetAddress}</DetailText>
          )}
          {companyInfo.shippingAddress.zipCode && (
            <DetailText>{companyInfo.shippingAddress.zipCode}</DetailText>
          )}
        </styled.Span>
        {companyInfo?.companyModel?.countryName && (
          <DetailText>{companyInfo.companyModel.countryName}</DetailText>
        )}
      </CompanyInfoContainer>

      <ButtonContainer>
        <BackButton onClick={updater.handleStepBackward}>
          {backLabel}
        </BackButton>

        <ForwardButton onClick={updater.handleStepForward} isActive>
          {proceedLabel}
        </ForwardButton>
      </ButtonContainer>
    </>
  );
}

export default SecondStep;

const ForwardButton = styled(SmallButton, {
  display: 'flex',
  alignItems: 'center',
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
  letterSpacing: theme.letterSpacing.medium,
  padding: { x: theme.spacing(7.5), y: theme.spacing(4) },

  ...media(theme.mediaQuery.mediaMinLarge, {
    width: 'auto',
  }),
});

const BackButton = styled(ForwardButton, {
  display: 'flex',
  alignItems: 'center',
});

const DetailText = styled.p({
  font: { size: theme.spacing(4), weight: theme.fontWeight.normal },
  lineHeight: theme.lineHeight.relaxed,
  marginBottom: theme.spacing(3),
});

const Heading = styled.h4({
  font: { size: theme.spacing(4), weight: theme.fontWeight.bold },
  paddingBottom: theme.spacing(2),
});

const CompanyInfoContainer = styled.div({
  padding: {
    xy: theme.spacing(8),
  },
  backgroundColor: theme.lightGray,
  ...media(theme.mediaQuery.mediaMaxLarge, {
    padding: {
      xy: theme.spacing(4),
    },
  }),
});

const ButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  margin: { x: 'auto', y: theme.spacing(4) },
  textAlign: 'center',
});

const AddressHeading = styled.p({
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
});
