import React, { useState, useEffect, useCallback } from 'react';
import { media } from '@glitz/core';

import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { registrationDataLayer } from '../utils/dataLayer.lib';

import ContentDivider from '../Shared/Divider/Divider';
import ContentArea from '../Shared/ContentArea/ContentArea';
import Alert from '../Shared/Common/Alert';
import RegistrationBar from './RegistrationBar';
import IconLink from '../Shared/IconLink/IconLink';
import { LinkType } from '../Enums/LinkType.enum';

// registration steps
import FirstStep from './Steps/FirstStep';
import SecondStep from './Steps/SecondStep';
import ThirdStep from './Steps/ThirdStep';
import FourthStep from './Steps/FourthStep';
import RegistrationComplete from './Steps/RegistrationComplete';

// actions
import { GetCompanyInfo, PostRegistration } from './RegisterApi/Register';

// models
import { RegistrationFormSettersType } from './Models/RegistrationFormSetters';
import RegistrationResponse from './Models/RegistrationResponse.interface';
import RegistrationPostModel from './Models/RegistrationPostModel.interface';
import RegistrationAddressModel from './Models/RegistrationAddressModel.interface';
import CompanyInfoProps from './CompanyInfoProps.interface';
import LeftPanel from '../LeftNavigationLayout/LeftPanel';
import RegistrationPageModel from './Models/RegistrationPageModel.interface';

import { styled, theme } from '../Theme';

const SHIPPING_FIELD_PREFIX = 'shipping_';

function RegistrationPage() {
  const {
    staticPages: { registrationPage, contactPage },
    translations: {
      'registrationpage/generalError': generalError,
      'common/contact': contactUsLabel,
    },
    languageRoute,
    siteRoute,
  } = useAppSettingsData();
  const {
    pageHeading,
    pageInfoText,
    mainContent,
    helpInfoHeader,
    helpInfoText,
  } = useCurrentPage<RegistrationPageModel>();

  // default states
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // company info
  const [companyName, setCompanyName] = useState<string>('');
  const [customerNumber, setCustomerNumber] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [telephone, setTelephone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [shippingAddress, setShippingAddress] = useState<
    RegistrationAddressModel
  >({} as RegistrationAddressModel);

  const [registrationResponse, setRegistrationResponse] = useState<
    RegistrationResponse
  >({} as RegistrationResponse);

  const companyInfoProps = {
    companyName,
    customerNumber,
    companyModel: { countryName: country },
    telephone,
    email,
    password,
    shippingAddress,
  } as CompanyInfoProps;

  // clear error message when user navigate
  useEffect(() => {
    setErrorMessage([]);
  }, [currentStep, setErrorMessage]);

  // handle post registration response
  useEffect(() => {
    if (registrationResponse.statusCode === 200) {
      registrationDataLayer(
        siteRoute,
        languageRoute,
        registrationResponse?.companyModel?.customerNumber
      );
      setCurrentStep(5);
    } else if (!!registrationResponse.errors?.length) {
      setErrorMessage(registrationResponse.errors);
    }
  }, [setCurrentStep, registrationResponse]);

  const handleStepForward = useCallback(() => {
    setCurrentStep(currentStep + 1);
  }, [currentStep]);

  const handleStepBackward = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const setFetchError = useCallback(() => {
    setErrorMessage([generalError]);
  }, [generalError]);

  const formatRegistrationData = useCallback((model: CompanyInfoProps) => {
    const res = {
      companyName: model.companyName,
      companyCode: model.customerNumber,
      phoneNumber: model.telephone,
      emailAddress: model.email,
      password: model.password,
      marketId: model.companyModel.countryCode,
      shippingAddress: {
        streetAddress: model.shippingAddress.streetAddress,
        city: model.shippingAddress.city,
        zipCode: model.shippingAddress.zipCode,
      },
    } as RegistrationPostModel;

    return res;
  }, []);

  const handlePostRegistration = useCallback(async () => {
    setIsLoading(true);
    const data = formatRegistrationData(companyInfoProps);
    await PostRegistration(
      registrationPage,
      data,
      setRegistrationResponse,
      setFetchError,
      languageRoute
    );
    setIsLoading(false);
  }, [
    formatRegistrationData,
    companyInfoProps,
    registrationPage,
    setFetchError,
    languageRoute,
  ]);

  const setShippingAddressFields = useCallback(
    (value: string, name?: string) => {
      const shippingFieldName =
        !!name && name.includes(SHIPPING_FIELD_PREFIX)
          ? name.replace(SHIPPING_FIELD_PREFIX, '')
          : name;

      if (!!shippingFieldName && typeof shippingFieldName === 'string') {
        setShippingAddress(prevState => ({
          ...prevState,
          [shippingFieldName]: value,
        }));
      }
    },
    []
  );

  const updateCompanyInfo = useCallback(
    (data: RegistrationResponse) => {
      // update company info
      setCompanyName(data.companyModel?.companyName || '');
      setShippingAddressFields(
        data.companyModel?.shippingAddress.city || '',
        'city'
      );
      setShippingAddressFields(
        data.companyModel?.shippingAddress.streetAddress || '',
        'streetAddress'
      );
      setShippingAddressFields(
        data.companyModel?.shippingAddress.zipCode || shippingAddress.zipCode,
        'zipCode'
      );
      setCountry(data.companyModel?.countryName || '');
      setCustomerNumber(
        data.companyModel?.customerNumber.toString() || customerNumber
      );
    },
    [customerNumber, setShippingAddressFields, shippingAddress.zipCode]
  );

  const fetchCompanyInfo = useCallback(async () => {
    setIsLoading(true);
    const res = await GetCompanyInfo(
      customerNumber,
      shippingAddress.zipCode,
      registrationPage,
      updateCompanyInfo,
      setFetchError,
      setErrorMessage
    );
    setIsLoading(false);
    if (res.statusCode === 200) {
      handleStepForward();
    }
  }, [
    customerNumber,
    shippingAddress.zipCode,
    registrationPage,
    setFetchError,
    handleStepForward,
    updateCompanyInfo,
  ]);

  const registrationFormSetters = {
    setCompanyName,
    setCustomerNumber,
    setTelephone,
    setEmail,
    setShippingAddressFields,
    setCountry,
    setPassword,
    handleStepForward,
    handleStepBackward,
    handlePostRegistration,
  } as RegistrationFormSettersType;

  const renderRegistrationStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <FirstStep
            updater={registrationFormSetters}
            companyInfo={companyInfoProps}
            fetchCompanyInfo={fetchCompanyInfo}
            isLoading={isLoading}
          />
        );
      case 2:
        return (
          <SecondStep
            updater={registrationFormSetters}
            companyInfo={companyInfoProps}
          />
        );
      case 3:
        return (
          <ThirdStep
            updater={registrationFormSetters}
            companyInfo={companyInfoProps}
          />
        );
      case 4:
        return (
          <FourthStep
            updater={registrationFormSetters}
            companyInfo={companyInfoProps}
            isLoading={isLoading}
          />
        );

      case 5:
        return (
          <RegistrationComplete registrationResponse={registrationResponse} />
        );
      default:
        break;
    }
  };

  const LeftComponent = (
    <>
      <Header>{pageHeading}</Header>
      <IntroText>{pageInfoText}</IntroText>
      <MaxDivider />
      {currentStep === 1 && (
        <>
          <SmallHeader>{helpInfoHeader}</SmallHeader>
          <IntroText>{helpInfoText}</IntroText>
          <StyledIconLink href={contactPage} type={LinkType.Arrow}>
            {contactUsLabel}
          </StyledIconLink>
        </>
      )}
      {Array.isArray(mainContent) && <ContentArea childItems={mainContent} />}
    </>
  );

  return (
    <LeftPanel leftComponent={LeftComponent}>
      <FormGridBox>
        <FormContainer>
          <FromTitle>Account Setup</FromTitle>
          <RegistrationBar currentStep={currentStep} />
          {renderRegistrationStep()}
          {!!errorMessage?.length &&
            errorMessage.map((msg, _i) => <Alert key={_i}>{msg}</Alert>)}
        </FormContainer>
      </FormGridBox>
    </LeftPanel>
  );
}

export default RegistrationPage;

const MaxDivider = styled(ContentDivider, {
  width: '100%',
  backgroundColor: theme.black,
  margin: { y: theme.spacing(8) },
});

const GridBox = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: { x: theme.spacing(16), y: theme.spacing(8) },
});

const FormGridBox = styled(GridBox, {
  backgroundColor: theme.white,
  padding: { x: 0, y: theme.spacing(8) },
});

const Header = styled.h1({
  font: { size: theme.eta, weight: theme.fontWeight.bold },
  color: theme.black,
  margin: { bottom: theme.spacing(4) },
  lineHeight: theme.lineHeight.tight,
  letterSpacing: theme.letterSpacing.moreWide,
});

const SmallHeader = styled.h3({
  font: { size: theme.epsilon, weight: theme.fontWeight.bold },
  color: theme.black,
  margin: { bottom: theme.spacing(4) },
  lineHeight: theme.lineHeight.tight,
  letterSpacing: theme.letterSpacing.moreWide,
});

const IntroText = styled.p({
  font: { size: theme.gamma },
  lineHeight: theme.lineHeight.normal,
  letterSpacing: theme.letterSpacing.nearMedium,
  color: theme.black,
});

const StyledIconLink = styled(IconLink, {
  marginTop: theme.spacing(4),
  marginLeft: 'auto',
});

const FormContainer = styled.div({
  font: { size: theme.psi, weight: theme.fontWeight.lighter },
  lineHeight: theme.lineHeight.normal,
  letterSpacing: theme.letterSpacing.medium,
  maxWidth: 400,
  width: '100%',
  margin: { x: 'auto' },

  ...media(theme.mediaQuery.mediaMaxMedium, {
    padding: { x: theme.spacing(4) },
  }),
});

const FromTitle = styled.h3({
  font: { size: theme.epsilon, weight: theme.fontWeight.bold },
  paddingBottom: theme.spacing(5),
});
