import React from 'react';
import { styled, theme } from '../../Theme';
import { media, pseudo } from '@glitz/core';

import { SmallButton } from '../../Shared/Button/Button';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import RegistrationPageModel from '../Models/RegistrationPageModel.interface';
import KexLink from '../../Shared/KexLink/KexLink';
import { useAppSettingsData } from '../../Shared/AppSettingsProvider/AppSettingsProvider';
import { useKexNavigate } from '../../Shared/KexRouter/KexRouter';
import RegistrationResponse from '../Models/RegistrationResponse.interface';

type PropTypes = {
  registrationResponse: RegistrationResponse;
};

function RegistrationComplete({ registrationResponse }: PropTypes) {
  const { step5Info, step5Heading } = useCurrentPage<RegistrationPageModel>();
  const {
    translations: {
      'account/email': emailAddressLabel,
      'registrationpage/steps/viewAccount': viewAccountLabel,
      'registrationpage/steps/startShopping': startShoppingLabel,
      'registrationpage/steps/shippingTextLabel': shippingTextLabel,
    },
    siteRoute,
    staticPages: { accountPage },
  } = useAppSettingsData();

  const kexNavigate = useKexNavigate();

  const goToStartPage = () => {
    kexNavigate(`/${siteRoute}`);
  };

  return (
    <>
      <Heading>{step5Heading}</Heading>
      <DetailText>{step5Info}</DetailText>
      <CompanyInfoContainer>
        <CustomerHeading>{`${registrationResponse.companyModel.companyName} - ${registrationResponse.companyModel.customerNumber}`}</CustomerHeading>
        <AddressSection>
          <AddressHeading>{shippingTextLabel}</AddressHeading>
          <DetailText>
            {registrationResponse.companyModel.shippingAddress.streetAddress}
          </DetailText>
          <DetailText>
            {registrationResponse.companyModel.shippingAddress.zipCode},{' '}
            {registrationResponse.companyModel.shippingAddress.city}
          </DetailText>
          <Text>
            <DetailText>
              {registrationResponse.companyModel.countryName}
            </DetailText>
          </Text>
        </AddressSection>

        <Section>
          <Text>
            <LabelText>{emailAddressLabel}:</LabelText>
            <DetailText>
              {registrationResponse.companyModel.emailAddress}
            </DetailText>
          </Text>
        </Section>
      </CompanyInfoContainer>
      <ButtonContainer>
        <Button isActive onClick={goToStartPage}>
          {startShoppingLabel}
        </Button>
        {registrationResponse.accountStatus === 1 && (
          <LinkContainer>
            <StyledKexLink href={accountPage}>{viewAccountLabel}</StyledKexLink>
          </LinkContainer>
        )}
      </ButtonContainer>
    </>
  );
}

export default RegistrationComplete;

const DetailText = styled.p({
  font: { size: theme.beta, weight: theme.fontWeight.normal },
  lineHeight: theme.lineHeight.relaxed,
  marginBottom: theme.spacing(2),
});

const AddressHeading = styled.p({
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
});

const LabelText = styled.p({
  font: { weight: theme.fontWeight.bold },
  marginBottom: theme.spacing(1),
  paddingRight: theme.spacing(1),
  display: 'inline',
});

const Heading = styled.h4({
  font: { size: theme.spacing(4), weight: theme.fontWeight.bold },
  paddingBottom: theme.spacing(2),
});

const CustomerHeading = styled.h4({
  font: { size: theme.spacing(4), weight: theme.fontWeight.bold },
  marginBottom: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  border: {
    bottom: {
      color: theme.thinGray,
      style: 'solid',
      width: theme.tiny,
    },
  },
});

const Text = styled.div({
  lineHeight: 'none',
  display: 'flex',
  font: { size: theme.beta },
});

const Section = styled.div({
  lineHeight: 'none',
  ...pseudo(':not(:last-child)', {
    marginBottom: theme.spacing(6),
  }),
});

const AddressSection = styled.div({
  marginBottom: theme.spacing(6),
  lineHeight: 'none',
});

const CompanyInfoContainer = styled.div({
  padding: {
    xy: theme.spacing(8),
  },
  backgroundColor: theme.lightGray,
  ...media(theme.mediaQuery.mediaMaxLarge, {
    padding: {
      xy: theme.spacing(4),
    },
  }),
});

const StyledKexLink = styled(KexLink, {
  fontSize: theme.spacing(4),
});

const ButtonContainer = styled.div({
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  margin: { x: 'auto', y: theme.spacing(4) },
});

const LinkContainer = styled.div({
  padding: {
    right: theme.spacing(4),
  },
  color: theme.blueDark,
});

const Button = styled(SmallButton, {
  font: { size: theme.spacing(4), weight: theme.fontWeight.normal },
  letterSpacing: theme.letterSpacing.medium,
  border: {
    xy: {
      width: theme.none,
    },
  },
  ...media(theme.mediaQuery.mediaMinLarge, {
    width: 'auto',
  }),
});
