import React, { useState } from 'react';
import { styled, theme } from '../../Theme';
import KexInput from '../../Shared/Input/KexInput';
import { KexInputValidation } from '../../Shared/Input/KexInputValidation';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import RegistrationPageModel from '../Models/RegistrationPageModel.interface';
import { useAppSettingsData } from '../../Shared/AppSettingsProvider/AppSettingsProvider';

import { RegistrationFormSettersType } from '../Models/RegistrationFormSetters';
import { FeedbackButton } from '../../Shared/Button/Button';
import CompanyInfoProps from './../CompanyInfoProps.interface';

type PropTypes = {
  updater: RegistrationFormSettersType;
  companyInfo: CompanyInfoProps;
  fetchCompanyInfo: () => Promise<void>;
  isLoading: boolean;
};

function FirstStep({ updater, companyInfo, fetchCompanyInfo }: PropTypes) {
  const { step1Info, step1Heading } = useCurrentPage<RegistrationPageModel>();
  const [stepOneValid, setFirstStepValid] = useState<boolean>(false);

  const {
    translations: {
      'account/customerNumber': customerNumber,
      'validation/customernumber': invalidCustomerNumber,
      'account/zipCode': zipCode,
      'validation/zipcode': invalidzipCode,
      'common/proceed': proceed,
    },
  } = useAppSettingsData();

  const checkCompanyInfo = async () => {
    await fetchCompanyInfo();
  };

  return (
    <>
      <Heading>{step1Heading}</Heading>
      <FormDetailText>{step1Info}</FormDetailText>
      <KexInputValidation
        onInputsValid={() => {
          setFirstStepValid(true);
        }}
        onInputsInvalid={() => {
          setFirstStepValid(false);
        }}
      >
        <KexInput
          title={customerNumber}
          validation={{
            required: true,
            errorMessage: invalidCustomerNumber,
            pattern: /(^[0-9]*$)/,
          }}
          onChange={value => {
            updater.setCustomerNumber(value);
          }}
          name="firstStep-customerNumber"
          value={'' || companyInfo.customerNumber}
          useDebounce
        />
        <KexInput
          title={zipCode}
          validation={{
            required: true,
            minLength: 3,
            maxLength: 10,
            errorMessage: invalidzipCode,
          }}
          onChange={updater.setShippingAddressFields}
          name="zipCode"
          value={companyInfo.shippingAddress?.zipCode || ''}
          useDebounce
        />
      </KexInputValidation>
      <ButtonContainer>
        <ForwardButton disabled={!stepOneValid} action={checkCompanyInfo}>
          {proceed}
        </ForwardButton>
      </ButtonContainer>
    </>
  );
}

export default FirstStep;

const ForwardButton = styled(FeedbackButton, {
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
  marginLeft: 'auto',
  letterSpacing: theme.letterSpacing.medium,
  padding: { x: theme.spacing(8), y: theme.spacing(4) },
});

const ButtonContainer = styled.div({
  margin: { x: 'auto', y: theme.spacing(4) },
});

const FormDetailText = styled.p({
  font: { size: theme.spacing(4), weight: theme.fontWeight.normal },
  lineHeight: theme.lineHeight.relaxed,
  marginBottom: theme.spacing(4),
});

const Heading = styled.h4({
  font: { size: theme.spacing(4), weight: theme.fontWeight.bold },
  paddingBottom: theme.spacing(2),
});
