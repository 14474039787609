import RegistrationResponse from './../Models/RegistrationResponse.interface';
import RegistrationPostModel from '../Models/RegistrationPostModel.interface';
import { updateHeader } from '../../Shared/Models/Headers/useQueryHeader';
import { updateUserState } from '../../Shared/UserContextProvider/useQueryUserState';

export async function GetCompanyInfo(
  companyCode: string,
  zipCode: string,
  url: string,
  setCompanyInfoResponse: (data: RegistrationResponse) => void,
  setFetchError: () => void,
  setErrorMessage: (messages: string[]) => void
) {
  const res = await fetch(
    `${url}GetCompany?customerNumber=${companyCode}&zipCode=${zipCode}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        credentials: 'same-origin',
      },
    }
  );
  const jsonRes = (await res.json()) as RegistrationResponse;
  setCompanyInfoResponse(jsonRes);
  if (jsonRes.statusCode !== 200) {
    setFetchError();
    setErrorMessage([jsonRes.message]);
  }
  return jsonRes;
}

export async function PostRegistration(
  url: string,
  registration: RegistrationPostModel,
  setRegistrationResponse: (data: RegistrationResponse) => void,
  setFetchError: () => void,
  languageRoute: string
) {
  try {
    const res = await fetch(`${url}PostRegistration`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        credentials: 'same-origin',
      },
      body: JSON.stringify(registration),
    });

    const jsonRes = (await res.json()) as RegistrationResponse;
    if (!!jsonRes.accountStatus) {
      await updateHeader(languageRoute);
      await updateUserState();
    }
    setRegistrationResponse(jsonRes);
  } catch (e) {
    setFetchError();
  }
}
