import React, { useState, useEffect } from 'react';
import { styled, theme } from '../../Theme';

import KexInput from '../../Shared/Input/KexInput';
import { KexInputValidation } from '../../Shared/Input/KexInputValidation';
import { RegistrationFormSettersType } from '../Models/RegistrationFormSetters';
import { SmallButton } from '../../Shared/Button/Button';
import { media } from '@glitz/core';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import RegistrationPageModel from '../Models/RegistrationPageModel.interface';
import { useAppSettingsData } from '../../Shared/AppSettingsProvider/AppSettingsProvider';
import CompanyInfoProps from '../CompanyInfoProps.interface';

type PropTypes = {
  updater: RegistrationFormSettersType;
  companyInfo: CompanyInfoProps;
};

function ThirdStep({ updater, companyInfo }: PropTypes) {
  const [password1, setPassword1] = useState<string>();
  const [password2, setPassword2] = useState<string>();
  const [passwordMatch, setPasswordMatch] = useState<boolean>(false);
  const [stepFourValid, setStepFourValid] = useState<boolean>(false);
  const { step3Info, step3Heading } = useCurrentPage<RegistrationPageModel>();
  const {
    translations: {
      'account/repeatPassword': repeatPassword,
      'account/password': password,
      'account/email': emailAddress,
      'validation/email': invalidEmailAddress,
      'common/proceed': proceed,
      'common/back': back,
      'validation/invalidpassword': invalidPassword,
      'validation/invalidrepeatpassword': invalidRepeatPassword,
      'registrationpage/password/atLeastCharacters': atLeastCharacters,
      'registrationpage/password/atLeastOneNumber': atLeastOneNumber,
      'registrationpage/password/atLeastOneUppercase': atLeastOneUppercase,
      'registrationpage/password/atLeastOneSpecialCharacter': atLeastOneSpecialCharacter,
      'registrationpage/password/passwordInformation': passwordInformationText,
    },
  } = useAppSettingsData();

  useEffect(() => {
    setPasswordMatch(password1 === password2);
  }, [password1, password2, setPasswordMatch]);

  useEffect(() => {
    if (passwordMatch && !!password2 && !!password1) {
      updater.setPassword(password2);
    }
  }, [updater, password1, password2, passwordMatch]);

  return (
    <>
      <Heading>{step3Heading}</Heading>
      <DetailText>{step3Info}</DetailText>
      <KexInputValidation
        onInputsValid={() => setStepFourValid(true)}
        onInputsInvalid={() => setStepFourValid(false)}
      >
        <KexInput
          title={emailAddress}
          validation={{
            required: true,
            minLength: 1,
            maxLength: 40,
            pattern: /(?=^.{1,241}$)^([\w.-]+)@([\w-]+)((\.(\w){2,})+)$/,
            errorMessage: invalidEmailAddress,
          }}
          value={companyInfo.email}
          onChange={updater.setEmail}
          name="thirdStep-email"
          useDebounce
        />
        <KexInput
          title={password}
          validation={{
            required: true,
            minLength: 8,
            maxLength: 30,
            pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-,.]).{8,}$/,
            errorMessage: invalidPassword,
          }}
          type="password"
          name="thirdStep-password1"
          useDebounce
          onChange={value => {
            setPassword1(value);
          }}
        />
        <KexInput
          title={repeatPassword}
          validation={{
            required: true,
            minLength: 8,
            maxLength: 30,
            pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-,.]).{8,}$/,
            valueToMatch: password1,
            errorMessage: !passwordMatch
              ? invalidRepeatPassword
              : invalidPassword,
          }}
          type="password"
          useDebounce
          name="thirdStep-password2"
          onChange={value => {
            setPassword2(value);
          }}
          onKeyDown={e => {
            stepFourValid &&
              passwordMatch &&
              e.key === 'Enter' &&
              updater.handleStepForward();
          }}
        />
      </KexInputValidation>
      <TextWrapper>
        <Text>{atLeastCharacters}</Text>
        <Text>{atLeastOneNumber}</Text>
        <Text>{atLeastOneUppercase}</Text>
        <Text>{atLeastOneSpecialCharacter}</Text>
        <PasswordText>{passwordInformationText}</PasswordText>
      </TextWrapper>
      <ButtonContainer>
        <BackButton onClick={updater.handleStepBackward}>{back}</BackButton>
        <ForwardButton
          disabled={!(stepFourValid && passwordMatch)}
          onClick={updater.handleStepForward}
          isActive
        >
          {proceed}
        </ForwardButton>
      </ButtonContainer>
    </>
  );
}

export default ThirdStep;

const DetailText = styled.p({
  font: { size: theme.spacing(4), weight: theme.fontWeight.normal },
  lineHeight: theme.lineHeight.relaxed,
  marginBottom: theme.spacing(4),
});

const Heading = styled.h4({
  font: { size: theme.spacing(4), weight: theme.fontWeight.bold },
  paddingBottom: theme.spacing(2),
});

const ButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  margin: { x: 'auto', y: theme.spacing(4) },
});

const ForwardButton = styled(SmallButton, {
  display: 'flex',
  alignItems: 'center',
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
  letterSpacing: theme.letterSpacing.medium,
  padding: { x: theme.spacing(7.5), y: theme.spacing(4) },
  ...media(theme.mediaQuery.mediaMinLarge, {
    width: 'auto',
  }),
});

const BackButton = styled(ForwardButton, {
  display: 'flex',
  alignItems: 'center',
});

const TextWrapper = styled.div({
  margin: { y: theme.spacing(3) },
});

const Text = styled.p({
  font: { size: theme.alpha, weight: theme.fontWeight.bold },
});

const PasswordText = styled.p({
  font: { size: theme.alpha },
  marginTop: theme.spacing(2),
});
