import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function ErrorIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
      <path
        d="M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z M8,1.9375 C4.6517737,1.9375 1.9375,4.6517737 1.9375,8 C1.9375,11.3482263 4.6517737,14.0625 8,14.0625 C11.3482263,14.0625 14.0625,11.3482263 14.0625,8 C14.0625,4.6517737 11.3482263,1.9375 8,1.9375 Z M9,10 L9,12 L7,12 L7,10 L9,10 Z M9,4 L9,9 L7,9 L7,4 L9,4 Z"
        id="Shape"
      ></path>
    </styled.Svg>
  );
}

export default styled(ErrorIcon);
