import { pseudo } from '@glitz/core';
import React from 'react';
import { styled, theme } from '../../Theme';

type PropType = {
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
  label?: string | JSX.Element;
};

function KexCheckbox(props: PropType) {
  return (
    <Label onChange={() => props.onChange(!props.isChecked)}>
      <Input
        type="checkbox"
        checked={props.isChecked}
        onChange={() => props.onChange(!props.isChecked)}
      />
      {props.label && <LabelText>{props.label}</LabelText>}
    </Label>
  );
}

const Label = styled.label({
  display: 'flex',
  alignItems: 'center',
});

const Input = styled.input({
  position: 'relative',
  width: theme.spacing(4),
  minWidth: theme.spacing(4),
  height: theme.spacing(4),
  minHeight: theme.spacing(4),
  WebkitAppearance: 'none',
  marginRight: theme.spacing(2),
  ...pseudo(':checked', {
    ':after': {
      content: '" "',
      position: 'absolute',
      width: theme.spacing(2),
      height: theme.spacing(2),
      top: theme.spacing(1),
      left: theme.spacing(1),
      backgroundColor: theme.black,
    },
  }),
  ':before': {
    position: 'absolute',
    content: '" "',
    width: '100%',
    height: '100%',
    border: {
      xy: {
        style: 'solid',
        width: theme.spacing(0.5),
        color: theme.black,
      },
    },
    left: 0,
    top: 0,
  },
  ':hover': {
    cursor: 'pointer',
  },
});

const LabelText = styled.div({
  fontSize: theme.alpha,
});

export default KexCheckbox;
