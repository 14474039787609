import React, { useState } from 'react';
import { styled, theme } from '../../Theme';
import { media, pseudo } from '@glitz/core';
import { SmallButton } from '../../Shared/Button/Button';
import { LoadingCircle } from '../../Shared/Icons';
import { RegistrationFormSettersType } from '../Models/RegistrationFormSetters';
import Translation, { translate } from '../../Shared/Common/Translate';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import RegistrationPageModel from '../Models/RegistrationPageModel.interface';
import { useAppSettingsData } from '../../Shared/AppSettingsProvider/AppSettingsProvider';
import CompanyInfoModel from '../CompanyInfoProps.interface';
import KexLink from '../../Shared/KexLink/KexLink';
import KexCheckbox from '../../Shared/KexCheckbox/KexCheckbox';

type PropTypes = {
  companyInfo: CompanyInfoModel;
  updater: RegistrationFormSettersType;
  isLoading: boolean;
};

function FourthStep({ companyInfo, updater, isLoading }: PropTypes) {
  const { step4Info, step4Heading } = useCurrentPage<RegistrationPageModel>();
  const [termsAgreed, setTermsAgreed] = useState<boolean>(false);

  const TermsOfUsePage = () => {
    return (
      <TermsLink target="_blank" href={tepeTermsOfUsePage}>
        {tepeTermsText}
      </TermsLink>
    );
  };
  const {
    staticPages: { tepeTermsOfUsePage },
    translations: {
      'account/username': usernameLabel,
      'account/email': emailAddressLabel,
      'account/register': registerLabel,
      'common/back': backLabel,
      'registrationpage/steps/shippingTextLabel': shippingTextLabel,
      'registrationpage/terms/text': termsText,
      'registrationpage/terms/tepeTermsText': tepeTermsText,
    },
  } = useAppSettingsData();

  return (
    <>
      <Heading>{step4Heading}</Heading>
      <IntroText>{step4Info}</IntroText>
      <CompanyInfoContainer>
        <CustomerHeading>{`${companyInfo?.companyName} - ${companyInfo.customerNumber}`}</CustomerHeading>
        <Section>
          <AddressHeading>{shippingTextLabel}</AddressHeading>
          <DetailText>{companyInfo.shippingAddress.streetAddress}</DetailText>
          <DetailText>
            {companyInfo.shippingAddress.zipCode},{' '}
            {companyInfo.shippingAddress.city}
          </DetailText>
          <Text>
            <DetailText>{companyInfo.companyModel?.countryName}</DetailText>
          </Text>
        </Section>

        <Section>
          <Text>
            <LabelText>{emailAddressLabel}:</LabelText>
            <DetailText>{companyInfo.email}</DetailText>
          </Text>
        </Section>
        <Section>
          <Text>
            <LabelText>{usernameLabel}:</LabelText>
            <DetailText>{companyInfo.email}</DetailText>
          </Text>
        </Section>
      </CompanyInfoContainer>
      <TermsWrapper>
        <KexCheckbox
          onChange={setTermsAgreed}
          isChecked={termsAgreed}
          label={
            <Translation
              translation={termsText}
              values={[<TermsOfUsePage />]}
            />
          }
        />
      </TermsWrapper>
      <ButtonContainer>
        <BackButton onClick={updater.handleStepBackward}>
          {backLabel}
        </BackButton>
        <ForwardButton
          onClick={updater.handlePostRegistration}
          disabled={!termsAgreed}
        >
          {isLoading ? (
            <StyledLoadingCircle isLoading={isLoading} />
          ) : (
            <>{registerLabel}</>
          )}
        </ForwardButton>
      </ButtonContainer>
    </>
  );
}

export default FourthStep;

const DetailText = styled.p({
  font: { size: theme.beta, weight: theme.fontWeight.normal },
  marginBottom: theme.spacing(1),
});

const AddressHeading = styled.p({
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
});

const IntroText = styled(DetailText, {
  font: { size: theme.gamma },
});

const StyledLoadingCircle = styled(LoadingCircle, {
  color: theme.white,
  height: theme.spacing(4),
  margin: { xy: 'auto' },
});

const LabelText = styled.p({
  font: { size: theme.beta, weight: theme.fontWeight.bold },
  marginBottom: theme.spacing(1),
  paddingRight: theme.spacing(1),
});

const Text = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  font: { size: theme.beta },
});

const Section = styled.div({
  ...pseudo(':not(:last-child)', {
    marginBottom: theme.spacing(6),
  }),
});

const Heading = styled.h4({
  font: { size: theme.spacing(4), weight: theme.fontWeight.bold },
  marginBottom: theme.spacing(2),
});

const CustomerHeading = styled.h4({
  font: { size: theme.spacing(4), weight: theme.fontWeight.bold },
  marginBottom: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  border: {
    bottom: {
      color: theme.thinGray,
      style: 'solid',
      width: theme.tiny,
    },
  },
});

const CompanyInfoContainer = styled.div({
  padding: {
    xy: theme.spacing(8),
  },
  backgroundColor: theme.lightGray,
  marginTop: theme.spacing(3),
  ...media(theme.mediaQuery.mediaMaxLarge, {
    padding: {
      xy: theme.spacing(4),
    },
  }),
});

const ButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  margin: { x: 'auto', y: theme.spacing(4) },
});

const ForwardButton = styled(SmallButton, {
  display: 'flex',
  alignItems: 'center',
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
  letterSpacing: theme.letterSpacing.medium,
  padding: { x: theme.spacing(7.5), y: theme.spacing(4) },

  ...media(theme.mediaQuery.mediaMinLarge, {
    width: 'auto',
  }),
});

const BackButton = styled(ForwardButton, {
  display: 'flex',
  alignItems: 'center',
});

const TermsLink = styled(KexLink, {
  textDecoration: 'underline',
});

const TermsWrapper = styled.div({
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(12),
});
