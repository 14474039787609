import React from 'react';
import { styled, theme } from '../Theme';
import { StyleOrStyleArray } from '@glitz/type';

const noOfNodes = 5;

type StepInfo = {
  currentStep: number;
};
function RegistrationBar(stepInfo: StepInfo) {
  return (
    <Rectangle css={CalculateGradient(stepInfo)}>
      {[...Array.from({ length: noOfNodes }, (_, i) => i + 1)].map((x, _) => (
        <Circle key={x} css={stepInfo.currentStep >= x ? Active : {}} />
      ))}
    </Rectangle>
  );
}

export default RegistrationBar;

const Rectangle = styled.div({
  height: '4px',
  width: '100%',
  borderRadius: '2px',
  backgroundColor: theme.lightGray,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(5),
});

const Circle = styled.div({
  height: theme.spacing(4),
  width: theme.spacing(4),
  backgroundColor: theme.lightGray,
  borderRadius: '50%',
});

const Active: StyleOrStyleArray = {
  backgroundColor: theme.blueDark,
};

function CalculateGradient(x: StepInfo): StyleOrStyleArray {
  var noOfSegments = noOfNodes - 1;
  var segmentPercentage = (100 / noOfSegments) * (x.currentStep - 1);

  return {
    backgroundImage: `linear-gradient(to right, ${theme.blueDark} 0%, ${theme.blueDark} ${segmentPercentage}%, ${theme.lightGray} ${segmentPercentage}%, ${theme.lightGray} 100%)`,
  };
}
